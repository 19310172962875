import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Notification } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "before-contributing",
      "style": {
        "position": "relative"
      }
    }}>{`Before contributing`}<a parentName="h1" {...{
        "href": "#before-contributing",
        "aria-label": "before contributing permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    You can contribute content to the HDS in different ways. Before you start contributing to HDS, please refer to following guidelines.
    </LeadParagraph>
    <p>{`Every component in HDS includes three parts:`}</p>
    <ol>
      <li parentName="ol">{`Design `}</li>
      <li parentName="ol">{`Implementation `}</li>
      <li parentName="ol">{`Documentation `}</li>
    </ol>
    <p>{`You may contribute in any part of these but all three parts have to be implemented and validated by HDS team before a feature can be released.`}</p>
    <Notification size="small" label="HDS team response time" type="alert" className="siteNotification" style={{
      marginBottom: 'var(--spacing-m)'
    }} mdxType="Notification">
The process of adding component to HDS can take a while. New feature request and proposed designs are first evaluated and prioritised on HDS roadmap. If the need for a feature is urgent, discuss this with HDS team.
    </Notification>
    <p>{`The easiest way to start contributing to HDS is joining the City of Helsinki Slack channel (`}<ExternalLink href="https://helsinkicity.slack.com/archives/CHCV3KTHA" mdxType="ExternalLink">{`#designsystem`}</ExternalLink>{`) and send us a message.`}</p>
    <h2 {...{
      "id": "tools-needed",
      "style": {
        "position": "relative"
      }
    }}>{`Tools needed`}<a parentName="h2" {...{
        "href": "#tools-needed",
        "aria-label": "tools needed permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Depending on the feature you want to be working on, you will need access to at least some of these tools:`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.sketch.com/" mdxType="ExternalLink">Sketch</ExternalLink>
      </li>
      <li parentName="ul">{`City of Helsinki organisation in `}<ExternalLink href="https://www.abstract.com/" mdxType="ExternalLink">{`Abstract`}</ExternalLink></li>
      <li parentName="ul">{`City of Helsinki Slack (channel: `}<ExternalLink href="https://helsinkicity.slack.com/archives/CHCV3KTHA" mdxType="ExternalLink">{`#designsystem`}</ExternalLink>{`)`}</li>
      <li parentName="ul">
        <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system" mdxType="ExternalLink">HDS GitHub repository</ExternalLink>
      </li>
    </ul>
    <h2 {...{
      "id": "contribution-process",
      "style": {
        "position": "relative"
      }
    }}>{`Contribution process`}<a parentName="h2" {...{
        "href": "#contribution-process",
        "aria-label": "contribution process permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`When faced with a need for a component or feature, you can ask yourself a few simple questions to decide whether you should propose a new component or feature to be added in HDS, or design a custom implementation:`}</p>
    <Image src="/images/getting-started/contributing-design/hds-contribution.png" alt="How to contribute to Helsinki Design System" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "1-find-out-does-it-already-exist",
      "style": {
        "position": "relative"
      }
    }}>{`1. Find out does it already exist?`}<a parentName="h3" {...{
        "href": "#1-find-out-does-it-already-exist",
        "aria-label": "1 find out does it already exist permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Before you start working or proposing a new component, it is important to check whether it already exists in HDS or in our roadmap.`}</p>
    <ol>
      <li parentName="ol">{`Check if it exists in `}<InternalLink href="/components" mdxType="InternalLink">{`HDS components`}</InternalLink>{`.`}</li>
      <li parentName="ol">{`Check if it exists on `}<InternalLink href="/about/roadmap" mdxType="InternalLink">{`HDS roadmap`}</InternalLink>{`.`}</li>
      <li parentName="ol">{`Check if someone else has already `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/issues" mdxType="ExternalLink">{`proposed it in GitHub issues`}</ExternalLink>{`.`}</li>
    </ol>
    <h3 {...{
      "id": "2-if-not-find-out-how-easily-could-a-existing-component-be-altered-to-fulfill-new-requirements",
      "style": {
        "position": "relative"
      }
    }}>{`2. If not, find out how easily could a existing component be altered to fulfill new requirements?`}<a parentName="h3" {...{
        "href": "#2-if-not-find-out-how-easily-could-a-existing-component-be-altered-to-fulfill-new-requirements",
        "aria-label": "2 if not find out how easily could a existing component be altered to fulfill new requirements permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Go through the list of existing `}<InternalLink href="/components" mdxType="InternalLink">{`HDS components`}</InternalLink>{`. See if some of the components could be slightly altered to suit your needs while still fulfilling existing requirements. If yes, move on to making a proposal for the change.`}</p>
    <h3 {...{
      "id": "3-if-not-find-out-if-is-this-component-something-that-is-needed-in-several-projects",
      "style": {
        "position": "relative"
      }
    }}>{`3. If not, find out if is this component something that is needed in several projects?`}<a parentName="h3" {...{
        "href": "#3-if-not-find-out-if-is-this-component-something-that-is-needed-in-several-projects",
        "aria-label": "3 if not find out if is this component something that is needed in several projects permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Think outside of the scope of your project. Can this component be utilized in other projects as well? If not, could you make it something more generic to match the needs of other projects? Discuss with other projects and the community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      